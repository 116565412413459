<div class="m-4">
    <div class="reveal-invite bg-white">
        <div class="flex flex-auto  mx-auto mb-4 campaignlive">
            <h3 class="font-bold">Your Relationship Manager Details</h3>
        </div>
        <div class="flex flex-auto mx-auto">
            <div class="col-md-9 p-0 payplancontent-width">
                <div class="pb-2">
                    <p  class="font-bold pb-2">Name</p>
                    <p  class=""><a href="">{{relationManager.fullname}}</a></p>
                </div>
                <div class="pb-2">
                    <p  class="font-bold pb-2">Email</p>
                    <p  class=""><a href="">{{relationManager.email}}</a></p>
                </div>
                <div class="pb-2">
                    <p  class="font-bold pb-2">Mobile Number</p>
                    <p  class=""><a href="">{{relationManager.phone}}</a></p>
                </div>
            </div>
        </div>
    </div>
</div>
