<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img class="w-7 h-7 rounded-full" *ngIf="showAvatar && user.profile_photo" [src]="user.profile_photo">
        <mat-icon *ngIf="!showAvatar || !user.profile_photo" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Signed in as</span>
            <span class="mt-1.5 text-md font-medium">{{user.email}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button *ngIf="user.userType === 'influencer'" mat-menu-item [routerLink]="['/pages/profile']">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button>
    <button mat-menu-item [routerLink]="['/pages/settings']">
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>Settings</span>
    </button>
    <button *ngIf="user.userType === 'brand'" mat-menu-item [routerLink]="['/pages/lists']">
        <mat-icon [svgIcon]="'heroicons_outline:clipboard-list'"></mat-icon>
        <span>My Lists</span> 
    </button>
    <button *ngIf="user.userType === 'brand'" mat-menu-item
    [routerLink]="['/pages/wallet/credits']">
        <mat-icon [svgIcon]="'heroicons_outline:credit-card'"></mat-icon>
        <span>Credits</span>
    </button>
    <button *ngIf="user.userType === 'brand'" mat-menu-item
    [routerLink]="['/choose-plan']">
        <mat-icon [svgIcon]="'heroicons_outline:trending-up'"></mat-icon>
        <span>Subscription</span>
    </button>
    <mat-divider class="my-2"></mat-divider>    
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>