<div *ngIf="loading">
  <http-loader-screen>
    <!-- <img src="assets/images/loader/loader-white.gif" alt="Bmi logo"> -->
    <img src="assets/images/loader/bmiheader-2-Slow-(White)-(1).gif" >
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </http-loader-screen>
</div>