<!-- Notifications toggle -->
<button mat-icon-button (click)="openPanel()" #notificationsOrigin>
    <ng-container *ngIf="unreadCount > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span
                class="flex items-center justify-center flex-shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-teal-600 text-indigo-50 text-xs font-medium">
                {{unreadCount}}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:bell'">
        <!-- <img src="/assets/images/bell-icon.png"> -->
        <!--  -->
    </mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>

    <div
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex flex-shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-white" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">Notifications</div>
            <div class="ml-auto">
                <button mat-icon-button [matTooltip]="'Mark all as read'" [disabled]="unreadCount === 0"
                    (click)="markAllAsRead()">
                    <mat-icon class="icon-size-5 text-white" [svgIcon]="'heroicons_solid:mail-open'"></mat-icon>
                </button>
            </div>
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
            <!-- Notifications -->
            <ng-container *ngFor="let notification of notifications; trackBy: trackByFn">
                <div class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
                    [ngClass]="{'unread': !notification.read}">

                    <div class="flex flex-auto py-5 pl-6">
                        <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                    </div>

                </div>

                <!-- Notification content template -->
                <ng-template #notificationContent>
                    <!-- Icon -->
                    <ng-container *ngIf="notification.icon && !notification.image">
                        <div
                            class="flex flex-shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray-100 dark:bg-gray-700">
                            <mat-icon class="icon-size-5" [svgIcon]="notification.icon">
                            </mat-icon>
                        </div>
                    </ng-container>
                    <!-- Image -->
                    <ng-container *ngIf="notification.image">
                        <img class="flex-shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center"
                            [src]="notification.image" [alt]="'Notification image'">
                    </ng-container>

                    <!-- Title, description & time -->
                    <div class="flex flex-col flex-auto">
                        <ng-container *ngIf="notification.type">
                            <ng-container [ngSwitch]="notification.type">
                                <ng-container *ngSwitchCase="1">
                                    <div class="font-semibold line-clamp-1">Wallet</div>
                                </ng-container>
                                <ng-container *ngSwitchCase="2">
                                    <div class="font-semibold line-clamp-1">Credit</div>
                                </ng-container>
                                <ng-container *ngSwitchCase="3">
                                    <div class="font-semibold line-clamp-1">Campaign</div>
                                </ng-container>
                                <ng-container *ngSwitchCase="4">
                                    <div class="font-semibold line-clamp-1">Channel</div>
                                </ng-container>
                                <ng-container *ngSwitchCase="5">
                                    <div class="font-semibold line-clamp-1">Application</div>
                                </ng-container>
                                <ng-container *ngSwitchCase="6">
                                    <div class="font-semibold line-clamp-1">Invitation</div>
                                </ng-container>
                                <ng-container *ngSwitchCase="7">
                                    <div class="font-semibold line-clamp-1">Hire</div>
                                </ng-container>
                                <ng-container *ngSwitchCase="8">
                                    <div class="font-semibold line-clamp-1">Order</div>
                                </ng-container>
                                <ng-container *ngSwitchCase="9">
                                    <div class="font-semibold line-clamp-1">Message</div>
                                </ng-container>
                                <ng-container *ngSwitchCase="10">
                                    <div class="font-semibold line-clamp-1">Payment</div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="notification.message">
                            <div class="line-clamp-2" [matTooltip]="notification.message"
                                [innerHTML]="notification.message" (click)="closePanel()"></div>
                        </ng-container>
                        <div class="mt-2 text-sm leading-none text-secondary">
                            {{notification.created_at | date:'MMM dd, h:mm a'}}
                        </div>
                    </div>
                </ng-template>
            </ng-container>

            <!-- No notifications -->
            <ng-container *ngIf="!notifications || !notifications.length">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
                        <mat-icon class="text-primary-500-700" [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">No notifications</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">When you have notifications,
                        they will appear here.</div>
                </div>
            </ng-container>

        </div>

    </div>

</ng-template>