<div class="fuse-vertical-navigation-wrapper">

    <!-- Header -->
    <div class="fuse-vertical-navigation-header">
        <ng-content select="[fuseVerticalNavigationHeader]"></ng-content>
    </div>

    <!-- Content -->
    <div class="fuse-vertical-navigation-content" fuseScrollbar
        [fuseScrollbarOptions]="{wheelPropagation: inner, suppressScrollX: true}" #navigationContent>

        <!-- Content header -->
        <div class="fuse-vertical-navigation-content-header">
            <ng-content select="[fuseVerticalNavigationContentHeader]"></ng-content>
        </div>

        <!-- Items -->
        <div *ngFor="let item of navigation; trackBy: trackByFn" class="scroll">

            <!-- Skip the hidden items -->
            <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

                <!-- Aside -->
                <ng-container *ngIf="item.type === 'aside'">
                    <fuse-vertical-navigation-aside-item [item]="item" [name]="name" [activeItemId]="activeAsideItemId"
                        [autoCollapse]="autoCollapse" [skipChildren]="true" (click)="toggleAside(item)">
                    </fuse-vertical-navigation-aside-item>
                </ng-container>

                <!-- Basic -->
                <ng-container *ngIf="item.type === 'basic'">
                    <fuse-vertical-navigation-basic-item [item]="item" [name]="name">
                    </fuse-vertical-navigation-basic-item>
                </ng-container>

                <!-- Collapsable -->
                <ng-container *ngIf="item.type === 'collapsable'">
                    <fuse-vertical-navigation-collapsable-item [item]="item" [name]="name"
                        [autoCollapse]="autoCollapse"></fuse-vertical-navigation-collapsable-item>
                </ng-container>

                <!-- Divider -->
                <ng-container *ngIf="item.type === 'divider'">
                    <fuse-vertical-navigation-divider-item [item]="item" [name]="name">
                    </fuse-vertical-navigation-divider-item>
                </ng-container>

                <!-- Group -->
                <ng-container *ngIf="item.type === 'group'">
                    <fuse-vertical-navigation-group-item [item]="item" [name]="name" [autoCollapse]="autoCollapse">
                    </fuse-vertical-navigation-group-item>
                </ng-container>

                <!-- Spacer -->
                <ng-container *ngIf="item.type === 'spacer'">
                    <fuse-vertical-navigation-spacer-item [item]="item" [name]="name">
                    </fuse-vertical-navigation-spacer-item>
                </ng-container>

            </ng-container>

        </div>

        <!-- Content footer -->
        <div class="fuse-vertical-navigation-content-footer">
            <ng-content select="[fuseVerticalNavigationContentFooter]"></ng-content>
        </div>

        <ng-container *ngIf="userType === 'BRAND' && menuItems?.plan_id">
            <div class="bottom-0 right-0 left-0 bg-default">
                <mat-divider class="mt-2 mb-2"></mat-divider>
                <div class="flex-auto p-2 leading-none">
                    <div class="text-sm font-medium">
                        <span class="text-secondary">Credits&nbsp;</span>
                        <span>{{ menuItems ? menuItems.totalCredit : 0 }}&nbsp;</span>
                        <span class="text-secondary">{{ " (" + (menuItems ? (((menuItems.totalUsedCredit * 100) /
                            (menuItems.totalCredit + menuItems.totalUsedCredit)) | number:'1.0-2') : 0)
                            + "% used)" }}</span>
                        <span (click)="topUp();"
                            class="inline-flex items-center font-bold text-xs float-right px-2.5 py-0.5 rounded-full tracking-wide bg-primary-600 text-primary-50 cursor-pointer">
                            TOPUP</span>
                    </div>
                    <mat-progress-bar class="mt-3 rounded-full" [color]="'warn'" [mode]="'determinate'"
                        [value]="menuItems? (menuItems.totalCredit + menuItems.totalUsedCredit): 0">
                        <!-- [value]="menuItems ? (menuItems.totalUsedCredit  * 100) / (menuItems.totalCredit + menuItems.totalUsedCredit) : 0"> -->
                    </mat-progress-bar>
                </div>
                <mat-divider class="mt-2 mb-2"></mat-divider>
                <div class="p-2 text-center text-secondary font-semibold" *ngIf="menuItems && menuItems.plan_id">
                    Active Plan&nbsp;
                    <span class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide
                    bg-warn-600 text-on-warn-600">{{ menuItems && menuItems.plan_id == 3 ? "Business" : menuItems && menuItems.plan_id == 2 ?
                        "Starter" : "Free" }}</span>
                    <!-- <span class="inline-flex text-center text-xs">{{ "Plan Renewal Date : " + (menuItems?.plan.expire_at | date : 'medium') }}</span> -->
                    <!-- <span class="inline-flex text-center text-xs">{{ "Next credit topup on : " + (userCredit?.last_topup_date | date : 'medium') }}</span> -->
                    <!-- <span *ngIf="userCredit?.last_topup_date !=null" class="inline-flex text-center text-xs">{{ "Next credit topup on : " + (userCredit?.last_topup_date | date) }}</span> -->
                </div>
                <div *ngIf="menuItems && (!menuItems.plan_id || menuItems.plan_id != 3)" class="p-2 text-center">
                    <span class="inline-flex items-center font-bold text-600 
            px-2.5 py-0.5 rounded-full tracking-wide bg-green-600 text-green-50 cursor-pointer"
                        [routerLink]="['/choose-plan']" >Upgrade </span>
                </div>
            </div>
        </ng-container>
    </div>

    <!-- Footer -->
    <div class="fuse-vertical-navigation-footer">
        <ng-content select="[fuseVerticalNavigationFooter]"></ng-content>
    </div>

</div>

<!-- Aside -->
<ng-container *ngIf="activeAsideItemId">
    <div class="fuse-vertical-navigation-aside-wrapper" fuseScrollbar
        [fuseScrollbarOptions]="{wheelPropagation: false, suppressScrollX: true}" [@fadeInLeft]="position === 'left'"
        [@fadeInRight]="position === 'right'" [@fadeOutLeft]="position === 'left'"
        [@fadeOutRight]="position === 'right'">

        <!-- Items -->
        <ng-container *ngFor="let item of navigation; trackBy: trackByFn">

            <!-- Skip the hidden items -->
            <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

                <!-- Aside -->
                <ng-container *ngIf="item.type === 'aside' && item.id === activeAsideItemId">
                    <fuse-vertical-navigation-aside-item [item]="item" [name]="name" [autoCollapse]="autoCollapse">
                    </fuse-vertical-navigation-aside-item>
                </ng-container>

            </ng-container>

        </ng-container>

    </div>
</ng-container>