<!-- Button -->
<button mat-icon-button [routerLink]="'pages/wallet'" >
    <!-- [matMenuTriggerFor]="userActions" -->
    <span class="relative">
        <mat-icon [svgIcon]="'heroicons_outline:currency-rupee'">
            <!-- <img src="assets/images/grey-rupee.jpg"> -->
        </mat-icon>
    </span>
</button>

<!-- <mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Wallet Balance</span>
            <span class="mt-1.5 text-md font-medium text-center">
                {{ (user.wallet_balance ? user.wallet_balance : 0) | currency : (user.currency ? user.currency : 'INR') : 'symbol' : '1.0-0' }}
            </span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button *ngIf="user.userType === 'brand'" mat-menu-item (click)="addMoney();" [disabled]="!user.plan_id || user.plan_id == 1">
        <mat-icon [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
        <span>Add Money</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button *ngIf="user.userType === 'brand'" mat-menu-item [routerLink]="'pages/wallet'" [disabled]="!user.plan_id || user.plan_id == 1">
        <mat-icon [svgIcon]="'heroicons_outline:currency-rupee'"></mat-icon>
        <span>Wallet</span>
    </button>
</mat-menu> -->